import React, { useState } from 'react';
import axios from 'axios';
import { Container, Grid, TextField, Button, Typography, Box, Paper, FormControlLabel, Checkbox, Link } from '@mui/material';
import { KeyboardArrowDown, PictureAsPdf } from '@mui/icons-material';
import Modal from 'react-modal';
import '../App.css';

Modal.setAppElement('#root');

const imageLinksSection1 = [
  "https://bialaperla.pl/wp-content/uploads/2024/06/cgr-konferencje-panorama.jpg",
  "https://bialaperla.pl/wp-content/uploads/2024/06/Czarna-Gora-Resort-11-scaled.jpg",
  "https://bialaperla.pl/wp-content/uploads/2024/06/P1744717-scaled.jpg"
];

function Section1() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    agreement: false,
  });

  const [formErrors, setFormErrors] = useState({});
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState(''); // 'success' or 'error'
  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = 'Imię jest wymagane';
    if (!formData.email) errors.email = 'E-mail jest wymagany';
    if (!formData.agreement) errors.agreement = 'Zgoda jest wymagana';
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      try {
        const response = await axios.post('https://mice.techsolver.pl/send_email.php', formData);
        setPopupMessage('Wiadomość e-mail została wysłana pomyślnie.');
        setPopupType('success');
      } catch (error) {
        setPopupMessage('Błąd podczas wysyłania wiadomości e-mail.');
        setPopupType('error');
      } finally {
        setPopupVisible(true);
      }
    } else {
      setFormErrors(errors);
    }
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const openModal = (image) => {
    setCurrentImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentImage("");
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <section className="current-section section1">
        <Container style={{ zIndex: 2, position: 'relative' }}>
          <Grid container spacing={4} alignItems="center" className="section-content" style={{ height: 'calc(100% - 50px)' }}>
            <Grid item xs={12} md={8} style={{ paddingRight: '40px', textAlign: 'justify' }}>
              <Typography variant="h4" className="section-title" style={{ marginBottom: '40px' }}>
                Wydarzenia grupowe i konferencje
              </Typography>
              <Typography variant="body1" className="section-text" style={{ marginBottom: '40px' }}>
                Zorganizuj niezapomniane wydarzenie w Czarna Góra Resort. Podczas gdy Ty będziesz budować relacje z gośćmi i korzystać z uroków obiektu oraz okolicy, my zajmiemy się całą resztą. Zadbamy o zakwaterowanie uczestników i prelegentów, sale konferencyjne, catering oraz atrakcje dla gości. 
                Opowiedz nam o swoim projekcie. 
                Dobierzemy <strong>lokalizację</strong>, <strong>termin</strong> i <strong>harmonogram</strong> działania. 
                Przydzielimy Ci <strong>doświadczonego opiekuna</strong> biznesowego, aby impreza udała się idealnie.

                Nie musisz zajmować się samodzielnie wyszukiwaniem miejsca na konferencję lub szkolenie, ani zlecać komuś w firmie organizacji zakwaterowania dla pracowników w delegacji. Skontaktuj się z nami.
              </Typography>
              <Box display="flex" justifyContent="space-between" marginBottom="40px">
                {imageLinksSection1.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Section 1 ${index + 1}`}
                    className="section2-image" // Reusing section2-image class for consistency
                    onClick={() => openModal(image)}
                  />
                ))}
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<PictureAsPdf />}
                  style={{ backgroundColor: '#faad14', color: '#1d3972' }}
                  href="https://www.sunandsnow.pl/Content/documents/Katalog_biznes_CGR_SP_k.pdf"
                  target="_blank"
                >
                  Pobierz katalog
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={3} className="section-form" style={{ padding: '10px', marginBottom: '20px' }}>
                <Typography variant="h6" style={{ marginBottom: '10px', textAlign: 'center', fontWeight: '600' }}>Zapytaj o ofertę</Typography>
                <form onSubmit={handleSubmit}>
                  <TextField
                    label="Imię"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    size="small"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    error={!!formErrors.name}
                    helperText={formErrors.name}
                  />
                  <TextField
                    label="E-mail"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    size="small"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                  />
                  <TextField
                    label="Numer telefonu"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    size="small"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                  <TextField
                    label="Treść"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={2}
                    size="small"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="agreement"
                        checked={formData.agreement}
                        onChange={handleInputChange}
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="body2" style={{ fontSize: '0.6rem' }}>
                        Wyrażam zgodę na otrzymywanie od Sun & Snow Sp. z o.o. z siedzibą w Warszawie (00-362), ul. Gałczyńskiego 4 treści marketingowych zawierających w szczególności informacje o produktach i usługach Grupy Sun & Snow na podany przeze mnie numer telefonu.
                        <br />
                        <Link href="https://www.sunandsnow.pl/Content/documents/klauzula_pl.pdf" target="_blank" rel="noopener noreferrer">
                          Więcej informacji tutaj
                        </Link>
                      </Typography>
                    }
                    style={{ marginTop: '10px' }}
                  />
                  {formErrors.agreement && (
                    <Typography variant="body2" style={{ color: 'red', fontSize: '0.6rem' }}>
                      {formErrors.agreement}
                    </Typography>
                  )}
                  <Box display="flex" justifyContent="center" marginTop="10px">
                    <Button variant="contained" color="primary" type="submit" style={{ fontSize: '0.8rem' }}>
                      Wyślij
                    </Button>
                  </Box>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <div className="scroll-down-arrow section1-arrow" onClick={() => scrollToSection('section2')}>
          <KeyboardArrowDown fontSize="large" />
        </div>
        <div className="background-container section1-background">
          <div className="background-left"></div>
          <div className="background-right">
            <video
              src="https://bialaperla.pl/wp-content/uploads/2024/06/zakadkaeventy.mp4"
              autoPlay
              loop
              muted
              className="background-video"
            />
          </div>
        </div>
      </section>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="image-modal"
        overlayClassName="image-modal-overlay"
      >
        <img src={currentImage} alt="Current" className="modal-image" />
        <button onClick={closeModal} className="modal-close-button">Close</button>
      </Modal>
      {isPopupVisible && (
        <div className={`popup ${popupType}`}>
          <p>{popupMessage}</p>
          <button onClick={() => setPopupVisible(false)}>Zamknij</button>
        </div>
      )}
    </div>
  );
}

export default Section1;
