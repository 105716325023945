import React, { useState } from 'react';
import { Container, Grid, TextField, Button, Typography, Box, Paper, FormControlLabel, Checkbox, Link } from '@mui/material';
import axios from 'axios';
import Modal from 'react-modal';
import '../App.css';

Modal.setAppElement('#root');

function Section3Part1() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    agreement: false,
  });

  const [formErrors, setFormErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = 'Imię jest wymagane';
    if (!formData.email) errors.email = 'E-mail jest wymagany';
    if (!formData.agreement) errors.agreement = 'Zgoda jest wymagana';
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      try {
        const response = await axios.post('https://mice.techsolver.pl/send_email.php', formData);
        setSuccessMessage('Wiadomość e-mail została wysłana pomyślnie.');
        setErrorMessage('');
      } catch (error) {
        setErrorMessage('Błąd podczas wysyłania wiadomości e-mail.');
        setSuccessMessage('');
      }
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <section className="current-section section3-part1" style={{ backgroundColor: '#1d3972', paddingTop: '10px', paddingBottom: '10px' }}>
      <Container style={{ zIndex: 2, position: 'relative' }}>
        <Grid container spacing={4} className="section-content">
          <Grid item xs={12}>
            <Typography variant="body2" style={{ color: '#fff', textAlign: 'justify', marginBottom: '20px', fontSize: '0.8rem' }}>
              Zorganizuj niezapomniane wydarzenie w jednym z naszych resortów. Podczas gdy Ty będziesz budować relacje z gośćmi i korzystać z uroków obiektu oraz okolicy, my zajmiemy się całą resztą. Zadbamy o zakwaterowanie uczestników i prelegentów, sale konferencyjne, catering oraz atrakcje dla gości. Opowiedz nam o swoim projekcie.
            </Typography>
            <Paper elevation={3} className="section-form" style={{ padding: '10px', marginBottom: '20px' }}>
              <Typography variant="h6" style={{ marginBottom: '10px', textAlign: 'center', fontWeight: '600' }}>Zapytaj o ofertę</Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Imię"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  error={!!formErrors.name}
                  helperText={formErrors.name}
                />
                <TextField
                  label="E-mail"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                />
                <TextField
                  label="Numer telefonu"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
                <TextField
                  label="Treść"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  multiline
                  rows={2}
                  size="small"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="agreement"
                      checked={formData.agreement}
                      onChange={handleInputChange}
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body2" style={{ fontSize: '0.6rem' }}>
                      Wyrażam zgodę na otrzymywanie od Sun & Snow Sp. z o.o. z siedzibą w Warszawie (00-362), ul. Gałczyńskiego 4 treści marketingowych zawierających w szczególności informacje o produktach i usługach Grupy Sun & Snow na podany przeze mnie numer telefonu.
                      <br />
                      <Link href="https://www.sunandsnow.pl/Content/documents/klauzula_pl.pdf" target="_blank" rel="noopener noreferrer">
                        Więcej informacji tutaj
                      </Link>
                    </Typography>
                  }
                  style={{ marginTop: '10px' }}
                />
                {formErrors.agreement && (
                  <Typography variant="body2" style={{ color: 'red', fontSize: '0.6rem' }}>
                    {formErrors.agreement}
                  </Typography>
                )}
                <Box display="flex" justifyContent="center" marginTop="10px">
                  <Button variant="contained" color="primary" type="submit" style={{ fontSize: '0.8rem' }}>
                    Wyślij
                  </Button>
                </Box>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Modal
        isOpen={!!successMessage || !!errorMessage}
        onRequestClose={() => {
          setSuccessMessage('');
          setErrorMessage('');
        }}
        contentLabel="Message Modal"
        className="message-modal"
        overlayClassName="message-modal-overlay"
      >
        {successMessage && (
          <Typography variant="body1" style={{ color: 'green' }}>
            {successMessage}
          </Typography>
        )}
        {errorMessage && (
          <Typography variant="body1" style={{ color: 'red' }}>
            {errorMessage}
          </Typography>
        )}
      </Modal>
    </section>
  );
}

export default Section3Part1;
